import React from "react";
import { DialogContent } from "@mui/material";
import { useGlobalDialogStore } from "../../hooks/useGlobalDialogStore";
import shallow from "zustand/shallow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

export const GlobalDialog: React.FC<{}> = (props) => {
  const { dialogOpen, closeDialog, dialogTitle, dialogText } = useGlobalDialogStore(
    (state) => ({
      dialogOpen: state.open,
      closeDialog: state.close,
      dialogTitle: state.title,
      dialogText: state.text,
    }),
    shallow
  );

  return (
    <>
      <Dialog onClose={closeDialog} open={dialogOpen} maxWidth="xl">
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
