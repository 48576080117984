import { ReactNode } from "react";
import create from "zustand";

type IState = {
  open: boolean;
  title: ReactNode;
  text: ReactNode;
  show: (title: ReactNode, text: ReactNode) => void;
  close: () => void;
};
export const useGlobalDialogStore = create<IState>((set) => ({
  open: false,
  title: "",
  text: "",
  show: (title: ReactNode, text: ReactNode) => set((state) => ({ open: true, title, text })),
  close: () => set((state) => ({ open: false, title: "", text: "" })),
}));
