import { format } from "date-fns";
import qs from "qs";
import React from "react";
import { axiosInstance } from "./utils/axios";

interface IAxiosConfiguratorProps {}

export const AxiosConfigurator: React.FC<IAxiosConfiguratorProps> = (props) => {
  axiosInstance.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => {
      const result = qs.stringify(params, {
        serializeDate: (date: Date) => format(date, "YYYY-MM-DDTHH:mm:ss"),
      });
      return result;
    };
    return config;
  });

  return <></>;
};
