import axios from "axios";
import { format } from "date-fns";
import qs from "qs";
export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
  timeout: 1_000_000,
  paramsSerializer: (params) => {
    const result = qs.stringify(params, {
      serializeDate: (date: Date) => format(date, "YYYY-MM-DDTHH:mm:ss"),
    });
    return result;
  },
});
