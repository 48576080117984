import { ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import theme, { portalTheme } from "../theme";
import { Shell } from "./Shell";
import ruLocale from "date-fns/locale/ru";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateLocalizationProvider from "@mui/lab/LocalizationProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { GlobalDialog } from "./helpers/GlobalDialog";
import { AxiosConfigurator } from "../AxiosConfigurator";
import { GlobalSpinner } from "./helpers/GlobalSpinner";

const queryClient = new QueryClient({ defaultOptions: { queries: { notifyOnChangeProps: "tracked" } } });

interface IAppProps {}

const App: React.FC<IAppProps> = (props) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <>
          <ReactQueryDevtools initialIsOpen />
          <ThemeProvider theme={process.env.REACT_APP_MAIN_MODULE === "sensor-panel" ? theme : portalTheme}>
            <DateLocalizationProvider language={ruLocale} dateAdapter={AdapterDateFns}>
              <Router>
                <GlobalDialog />
                <GlobalSpinner />
                <AxiosConfigurator />
                <Shell />
              </Router>
            </DateLocalizationProvider>
          </ThemeProvider>
        </>
      </QueryClientProvider>
    </>
  );
};

export default App;
