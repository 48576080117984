import create from "zustand";

type IState = {
  inProgress: boolean;
  setInProgress: (inProgress: boolean) => void;
};
export const useGlobalSpinnerStore = create<IState>((set) => ({
  inProgress: false,
  setInProgress: (inProgress: boolean) => set(() => ({ inProgress })),
}));
