import React from "react";
import { CircularProgress, DialogContent } from "@mui/material";
import { useGlobalSpinnerStore } from "../../hooks/useGlobalSpinnerStore";
import shallow from "zustand/shallow";
import Dialog from "@mui/material/Dialog";

export const GlobalSpinner: React.FC<{}> = (props) => {
  const { inProgress } = useGlobalSpinnerStore(
    (state) => ({
      inProgress: state.inProgress,
    }),
    shallow
  );

  return (
    <>
      <Dialog open={inProgress}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};
