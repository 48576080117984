import { createTheme } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";
import { red } from "@mui/material/colors";
import deepmerge from "deepmerge";
import { ruRU } from "@mui/material/locale";

import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bodyBigger: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyBigger?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyBigger: true;
  }
}
declare module "@mui/material/styles/components" {
  interface Components {
    StyledTextField?: {
      defaultProps?: ComponentsProps["MuiTextField"];
      styleOverrides?: ComponentsOverrides["MuiTextField"];
      variants?: ComponentsVariants["MuiTextField"];
    };
    StyledNavMenu?: {
      defaultProps?: ComponentsProps["MuiListItemText"];
      styleOverrides?: ComponentsOverrides["MuiListItemText"];
      variants?: ComponentsVariants["MuiListItemText"];
    };
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    fieldText: React.CSSProperties["color"];
    roomColor: React.CSSProperties["color"];
    roomReservedColor: React.CSSProperties["color"];
    roomHighlightedColor: React.CSSProperties["color"];
    roomOtherColor: React.CSSProperties["color"];
  }
  interface PaletteOptions {
    fieldText: React.CSSProperties["color"];
    roomColor: React.CSSProperties["color"];
    roomReservedColor: React.CSSProperties["color"];
    roomHighlightedColor: React.CSSProperties["color"];
    roomOtherColor: React.CSSProperties["color"];
    workplaceReservedColor: React.CSSProperties["color"];
  }
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    free: React.CSSProperties["color"];
    soon: React.CSSProperties["color"];
    occupied: React.CSSProperties["color"];
    navPanel: React.CSSProperties["color"];
    dark: React.CSSProperties["color"];
  }
}

// Create a theme instance.
const paperBackgroundColor = "#272B3E";
const navPanelBackgroundColor = "#212433";
const orangeColor = "#EE774D";
const fieldTextColor = "#808080";
export const cardBackgroundColor = "#171925";
// const paperGradientBottom = "#3F4557";
const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#416BAE",
        dark: "#416BAE",
      },
      secondary: {
        main: orangeColor,
        dark: orangeColor,
      },
      fieldText: fieldTextColor,
      background: {
        paper: paperBackgroundColor,
        navPanel: navPanelBackgroundColor,
        default: "#4A4E61",
        free: "#009900",
        soon: orangeColor,
        occupied: "#CC0000",
        dark: cardBackgroundColor,
      },
      error: {
        main: red.A400,
      },
      roomColor: "#00B6EF80",
      roomReservedColor: "#F4504F80",
      roomHighlightedColor: "#EE774D80",
      roomOtherColor: "#F4F4F480",
      workplaceReservedColor: "#F4504F",
    },
    typography: {
      fontFamily: ["Calibri", "Roboto", "Arial", "sans-serif"].join(","),
      bodyBigger: {
        fontSize: 15.5,
      },
    },

    components: {
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          size: "small",
        },
      },
      StyledTextField: {
        styleOverrides: {
          root: {
            paddingLeft: 8,
            color: fieldTextColor,
            borderBottom: `2px solid ${fieldTextColor}`,
          },
        },
      },
      StyledNavMenu: {
        styleOverrides: {
          root: {},
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          color: "inherit",
        },
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },

      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: cardBackgroundColor,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          dense: {
            minHeight: 40,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: paperBackgroundColor,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: orangeColor,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          dense: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          rounded: {
            borderRadius: 16,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 32,
            "& > .MuiIconButton-root": {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiSpeedDialIcon: {
        styleOverrides: {
          root: {
            height: 48,
          },
        },
      },
      MuiSpeedDialAction: {
        styleOverrides: {
          fab: {
            backgroundColor: "white",
            color: paperBackgroundColor,
            width: 60,
            height: 60,
            "& .MuiSvgIcon-root": {
              width: "2em",
              height: "2em",
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            color: paperBackgroundColor,
            width: 60,
            height: 60,
            "& .MuiSvgIcon-root": {
              width: "2em",
              height: "2em",
            },
          },
          primary: {
            backgroundColor: "white",
            color: paperBackgroundColor,
            "&:hover": {
              backgroundColor: "white",
              color: paperBackgroundColor,
            },
            "@media (hover: none)": {
              "&:hover": {
                backgroundColor: "white !important",
                color: paperBackgroundColor,
              },
            },
          },
        },
      },
    },
  },
  ruRU
);

export const portalTheme = deepmerge(theme, {
  components: {
    MuiTextField: {
      defaultProps: {
        margin: "none",
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          color: "black",
        },
        inputMarginDense: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: 20,
        },
      },
    },
    StyledTextField: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    StyledNavMenu: {
      styleOverrides: {
        root: {
          "& .MuiListItemText-primary": {
            fontSize: 16,
          },
        },
      },
    },
  },
});

export default theme;
